import {useEffect, useState} from "react";
import GetCommunityForm from "../../Common/ApiCall/GetCommunityForm";
import {BadNotif} from "../../Common/Utils/SendNotification";
import {useParams, useSearchParams} from "react-router-dom";
import something from '../../Common/Images/something.png'
import {Button, Form, Input, message, Spin} from "antd";
import CommunityFormSubmit from "../../Common/ApiCall/CommunityFormSubmit";
import {capitalize, validateEmail} from "../BrandPage/BrandPage";
import isMobile from "is-mobile";
import SEO from "../../components/SEO";
import Thanks from "./Thanks";
import CommunityDetails from "../../Common/Models/CommunityDetails";
import Loader from "../../components/Loader";
import FAQs from "./FAQs";
import ProductPage from "../BrandPage/ProductPage";
import CollectAddress from "../BrandPage/CollectAddress";

export type pageTypes = "details" | "product" | "address"

export type AddressObj = {
  line_1: string,
  line_2: string,
  city: string,
  state: string,
  country: string,
  pin: string
}

/*
0 - Form
1 - Submit (Not Eligible) [Thanks Page]
2 - Submit (Eligible) - Private
3 - Submit (Eligible) - Public
 */

const BrandApplication = () => {
  const p = useParams()
  const [bName, setBname] = useState<string | number | null>(null)
  const [it, setIt] = useState(true)
  const [load, setLoad] = useState(false)
  const [iForm] = Form.useForm()

  const [curr, setCurr] = useState(0)
  const [currPage, setCurrPage] = useState<pageTypes>("details")
  const [totSteps, setTotSteps] = useState(2)
  const [done, setDone] = useState<0 | 1 | 2 | 3>(0)
  const [brandDetails, setBrandDetails] = useState<CommunityDetails | null>(null)
  const [adr, setAdr] = useState<AddressObj>({
    line_1: "", line_2: "", city: "", country: "", pin: "", state: ""
  })
  const [val, setVal] = useState<{
    email: string,
    instagram: string,
    contact: string,
    address: string,
    product_id: number | null,
    name: string, youtube: string
  }>({
    email: "",
    instagram: "",
    contact: "",
    address: "",
    product_id: null,
    name: "",
    youtube: ""
  })

  useEffect(() => {getBrandDetails()}, [bName])

  async function getBrandDetails() {
    if(bName) {
      const res = await GetCommunityForm(bName)
      setIt(false)
      if(res.success) {
        let data = res.data
        if(data.influencer_giveaway_products) {
          setVal({
            ...val,
            product_id: data.influencer_giveaway_products[0].id
          })
        }
        setTotSteps( (data.show_address ? 1 : 0)
          + ((data.is_giveaway_active && data.influencer_giveaway_products && data.influencer_giveaway_products.length > 0) ? 1 : 0))
        setBrandDetails(data)
      } else {
        BadNotif(res)
      }
    }
  }

  function getSubmitButtonDetails() {
    switch (currPage) {
      case "details": return totSteps===0 ? "submit" : "next";
      case "address": return "submit";
      case "product": return totSteps === 1 ? "submit" : "next";
      default: return "submit"
    }
  }

  function submitUserDetails(e: any) {
    if(e) {e.preventDefault()}
    let a = iForm.getFieldsValue()
    if(!a["ib-community-instagram"]) {
      message.warn("Enter Instagram Username to Continue.");return;
    }
    if(!a["ib-community-email"]) {
      message.warn("Enter Email to Continue.");return;
    }
    const username = a["ib-community-instagram"].trim()
    if(username.indexOf(" ") >= 0) {
      console.warn("Username contains spaces", username);
      message.warn("Instagram Username doesn't contains spaces.\nCopy and paste your instagram username or Instagram Profile URL.");
      return;
    }
    if(username.indexOf("@gmail.com") >= 0) {
      console.warn("Username contains email", username);
      message.warn("Enter your Instagram Username not email.\nCopy and paste your instagram username or Instagram Profile URL.");
      return;
    }
    if(brandDetails?.is_giveaway_active && brandDetails.influencer_giveaway_products && brandDetails.influencer_giveaway_products.length > 0) {
      setCurr(curr + 1)
      setCurrPage('product')
    } else {
      if(brandDetails?.show_address) {
        setCurr(curr+1)
        setCurrPage('address')
      } else {
        formSubmit(e)
      }
    }
  }

  function nextProdPage(e: any) {
    if(totSteps===2) {
      setCurrPage('address')
      setCurr(curr+1)
    } else {
      formSubmit(e)
    }
  }


  const nextPage = (e: any = null) => {
    let a = iForm.getFieldsValue()
    if(!a["ib-community-instagram"]) {
      message.warn("Enter Instagram Username to Continue.");return;
    }
    if(!a["ib-community-email"]) {
      message.warn("Enter Email to Continue.");return;
    }
    console.log(currPage, curr, a, totSteps)
    switch (currPage) {
      case "details": submitUserDetails(e);break;
      case "product": nextProdPage(e);break;
      case "address": formSubmit(e)
    }
  }

  const formSubmit = async (evt = null) => {
    //@ts-ignore
    if(evt) {evt.preventDefault()}
    let a = iForm.getFieldsValue()
    let e = true
    let ad = null
    if(brandDetails?.show_address) {
      if(!(adr.line_1 && adr.line_2 && adr.city && adr.state && adr.country && adr.pin)) {
        console.warn("Address Not Found")
        message.warn("Enter Address to Continue")
        return;
      } else {
        ad = [adr.line_1, adr.line_2, adr.city, adr.state, adr.country, adr.pin].join(", ")
      }
    }
    let b = {
      email: a["ib-community-email"],
      page_id: bName,
      username: a["ib-community-instagram"],
      eligible: "no" || (e ? "yes" : "no"),
      mobile: isMobile() ? "yes" : "no",
      contact: val.contact,
      address: ad,
      product_id: val.product_id,
      name: val.name,
      description: val.youtube,
    }
    if(!b.username) {
      message.warn("Enter Instagram Username to Continue.");return;
    }
    if(b.username.indexOf("@gmail.com") >= 0) {
      const EMAIL_IN_USERNAME_WARNING_MESSAGE = "Enter your Instagram Username not email.\nCopy and paste your instagram username or Instagram Profile URL."
      console.warn("Username contains email", b.username);
      message.warn(EMAIL_IN_USERNAME_WARNING_MESSAGE);
      return;
    }
    if(!b.email) {
      message.warn("Enter Email to Continue.");return;
    }
    if(!validateEmail(b.email)) {
      message.warn("Invalid Email.");return;
    }
    setLoad(true)
    const res = CommunityFormSubmit(b)
    setLoad(false)
    setDone(1)
  }

  useEffect(() => {
    if(p && p.brand) setBname(p.brand)
  }, [])

  if(it || !brandDetails) {
    return (
      <div className={"hero theme-bg"}>
        <div className={"hero-body"}>
          <div className={"container has-text-centered"}>
            <Loader />
          </div>
        </div>
      </div>
    )
  }

  function hexfix(str: string) {
    str = str.substring(1)
    if (str.length === 3) {
      str = str.split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
    return str
  }

  const getTextColor = (color: string) => {
    color = hexfix(color)
    let c = "#fff"
    //if(l && l < 20) c = "#fff"
    return c
  }

  if(load) {
    return(
      <div className={"hero theme-bg"}>
        <SEO
          title={capitalize(brandDetails?.brand_name + " | Influencerbit Community")}
          img={brandDetails.brand_logo ?? ""}
        />
        <div className={"hero-body"}>
          <div className={"container"}>
            <div className={"columns is-mobile is-multiline is-vcentered"}>
              <div className={"column is-6-tablet is-12-mobile"}>
                <div className={"ib-card-parent"}>
                  <div
                    className={"card ib-community-card"}
                    style={{
                      minHeight: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div
                      className={"card-content ib-card-content has-text-centered"}
                      style={{
                        backgroundColor: brandDetails.bg_color,
                      }}
                    >
                      <Spin />
                    </div>
                  </div>
                </div>
              </div>
              <div className={"column is-6-mobile-only has-text-centered"}>
                <img
                  src={something}
                  style={{maxWidth: "80%"}}
                  className={"mb-3 is-pulled-left"}
                />
                <img
                  className={"ib-community-image"}
                  src={brandDetails.images.length>0 ? brandDetails.images[0] : "https://uploads.dovetale.net/processed-landing-page-images/ac8da5449ec2d7541d3925da3054749d.png"}
                />
              </div>
              <div className={"column is-6-mobile-only has-text-centered"}>
                <img
                  src={brandDetails.images.length>1 ? brandDetails.images[1] : "https://uploads.dovetale.net/processed-landing-page-images/ac8da5449ec2d7541d3925da3054749d.png"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div className={"hero theme-bg"}>
      <SEO
        title={capitalize(brandDetails?.brand_name + " | Influencerbit Community")}
        img={brandDetails.brand_logo ?? ""}
      />
      <div className={"hero-body"}>
        <div className={"container"}>
          <div className={"columns is-mobile is-multiline is-vcentered"}>
            <div className={"column is-6-tablet is-12-mobile"}>
              <Thanks
                status={done}
                brandDetails={brandDetails}
                username={iForm.getFieldValue("ib-community-instagram") || ""}
              />
              <div className={"ib-card-parent"}>
                <div
                  className={"card ib-community-card"}
                  hidden={done !== 0}
                >
                  <div
                    className={"card-content ib-card-content has-text-centered py-6"}
                    style={{
                      backgroundColor: brandDetails.bg_color
                    }}
                  >
                    <h4 className={"title is-size-3"}>
                      {brandDetails.brand_title}
                    </h4>
                    <div className={"content"}>
                      {brandDetails.brand_description}
                      <br /><br />
                      <Form
                        className={"has-text-left"}
                        form={iForm}
                        onFinish={() => nextPage()}
                      >
                        <Form.Item
                          noStyle
                          hidden={currPage!=='details'}
                        >
                          <Form.Item
                            name={"ib-community-email"}
                            rules={[{
                              required: true, type: 'email'
                            }]}
                            noStyle
                          >
                          </Form.Item>
                          <div
                            className={"ib-form-email-comp"}
                          >
                            <i
                              className="fa fa-ig-ib fa-2x fa-envelope"
                              aria-hidden="true"
                            />
                            <input
                              name={"email"}
                              type={"email"}
                              className={"ib-input ib-instagram-email-input"}
                              placeholder={"Enter Email Here"}
                              defaultValue={iForm.getFieldValue("ib-community-email")}
                              onChange={e => iForm.setFieldsValue({"ib-community-email": e.target.value})}
                              required
                            />
                          </div>
                        </Form.Item>
                        <Form.Item
                          noStyle
                          hidden={currPage!=='details'}
                        >
                          <Form.Item
                            name={"ib-community-instagram"}
                            noStyle
                          >
                          </Form.Item>
                          <div
                            className={"ib-form-instagram-input-comp"}
                          >
                            <i
                              className="fa fa-ig-ib fa-2x fa-instagram"
                              aria-hidden="true"
                            />
                            <input
                              type="text"
                              id="ib-instagram-username-input"
                              className="ib-input ib-instagram-username-input"
                              placeholder={"Your Instagram Username"}
                              onChange={e => iForm.setFieldsValue({"ib-community-instagram": e.target.value})}
                              defaultValue={iForm.getFieldValue("ib-community-instagram")}
                              required
                            />
                          </div>
                        </Form.Item>
                        {currPage==='product' && brandDetails.is_giveaway_active && brandDetails.influencer_giveaway_products && brandDetails.influencer_giveaway_products.length > 0 && (
                          <ProductPage
                            products={brandDetails.influencer_giveaway_products}
                            setProduct={(a: number) => setVal({...val, product_id: a})}
                            productId={val.product_id}
                            brand={brandDetails}
                          />
                        )}
                        {currPage==='address' && brandDetails.show_address && (
                          <CollectAddress
                            address={adr}
                            setAddress={(ad: AddressObj) => setAdr(ad)}
                          />
                        )}
                        {brandDetails.show_contact && currPage==='details' && (
                          <>
                            <div
                              className={"ib-form-email-comp"}
                            >
                              <i
                                className="fa fa-ig-ib fa-2x fa-phone"
                                aria-hidden="true"
                              />
                              <input
                                name={"contact"}
                                type={"text"}
                                className={"ib-input ib-instagram-email-input"}
                                placeholder={"Your Phone Number"}
                                onChange={e => setVal({...val, contact: e.target.value})}
                                value={val.contact}
                                required
                              />
                            </div>
                            {/*                                          <br className={"is-hidden-mobile"} />*/}
                          </>
                        )}
                        {currPage==='details' && brandDetails.show_youtube && (
                          <div
                            className={"ib-form-input-comp"}
                          >
                            <i
                              className="fa fa-ig-ib fa-2x fa-youtube-play"
                              aria-hidden="true"
                            />
                            <input
                              type="text"
                              id="ib-instagram-youtube-input"
                              className="ib-input ib-instagram-email-input"
                              placeholder={"Youtube (Optional)"}
                              onChange={e => setVal({...val, youtube: e.target.value})}
                              value={val.youtube}
                              name="youtube"
                            />
                          </div>
                        )}
                        <Form.Item
                          className={"has-text-centered"}
                          shouldUpdate
                        >
                          {() => {
                            return(
                              <Button
                                type={'primary'}
                                shape={'round'}
                                htmlType={'submit'}
                                loading={load}
                                style={{
                                  backgroundColor: brandDetails.button_color,
                                  color: getTextColor(brandDetails.button_color)
                                }}
                                size={'large'}
                                disabled={load}
                                className={"is-uppercase px-5 has-text-weight-bold"}
                              >
                                {load ? <>Wait... <Spin /></> : getSubmitButtonDetails()}
                              </Button>
                            )
                          }}
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"column is-6-mobile-only has-text-centered"}>
              <img
                className={"ib-community-image"}
                src={"https://influencex-profile-pictures.nyc3.digitaloceanspaces.com/campaigns/pages/9/a.png"}

                // src={brandDetails.images.length>0 ? brandDetails.images[0] : "https://uploads.dovetale.net/processed-landing-page-images/ac8da5449ec2d7541d3925da3054749d.png"}
              />
            </div>
          </div>
        </div>
        <FAQs
          campaign_id={brandDetails.id}
        />
      </div>
    </div>
  )
}

export default BrandApplication