import communityDetails from "../../Common/Models/CommunityDetails";

type ThanksProps = {
  status: 0 | 1 | 2 | 3,
  username: string,
  brandDetails: communityDetails | null
}

const Thanks = (
  {
    status,
    username,
    brandDetails
  }: ThanksProps
) => {

  if(status === 0 || !brandDetails) return null

  const toTemplate = (s: string) => {
    let influencer = username
    influencer = influencer.replace("@", "")
    return eval('`' + s + '`')
  }

  if(status === 1) {
    return (
      <div className={"ib-card-"}>
        <div className={"card py-6 ib-community-card switched"}>
          <div className={"card-content has-text-centered ib-card-content"}>
            <h4 className={"title is-size-3-tablet is-size-5"}>
              {toTemplate(brandDetails.thanks_headline)}
            </h4>
            <div className={"content pt-5"}>
              Thank You! We will reach out to you soon.
            </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div className={"ib-card-parent"}>
      <div className={"card ib-community-card switched"}>
        <div className={"card-content ib-card-content has-text-centered"}>
          <h4 className={"title is-size-3-tablet"}>
            {toTemplate(brandDetails.thanks_headline)}
          </h4>
          <div className="has-text-centered">
            <p className="join-head is-size-4-desktop pb-2">
              {toTemplate(brandDetails.thanks_box_head)}
            </p>
            <p className="ibBox dark has-text-left has-text-weight-bold">
              {toTemplate(brandDetails.thanks_box_content)}
            </p>
          </div>
          <div className={"content has-text-left pt-5"}>
            <p
              dangerouslySetInnerHTML={{__html: toTemplate(brandDetails.thanks_message)}}
            />
            <br />
            <p
              hidden={status !== 2}
            >
              PS: Please <b>make your Instagram account {username?.replace("@", "")} public</b> before posting so we can detect the mention and send the discount code.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Thanks