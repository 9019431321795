import GiveawayProduct from "../../Common/Models/GiveawayProduct";
import Slider from "react-slick";
import CommunityDetails from "../../Common/Models/CommunityDetails";
import BrandDetails from "../../Common/Models/brandDetails";

type ProductPageProps = {
  products: GiveawayProduct[],
  setProduct: Function,
  productId: number | null,
  brand: CommunityDetails
}

const ProductPage = (
  {
    products,
    setProduct,
    productId,
    brand
  }: ProductPageProps
) => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: (products.length > 2) ? 3 : products.length,
    slidesToScroll: 1,
    className: "slick-div",
    centerPadding: "",
    responsive: [
      {
        breakpoint: 1244,
        settings: {
          slidesToShow: 2,
          arrows: true
        }
      }
    ]
/*    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ],*/
  };

  return (
    <div
      className={"mb-5"}
    >
      <h3
        className={"heading is-capitalized is-size-4-desktop is-size-5-tablet is-size-6-mobile has-text-left-tablet py-2 has-text-weight-semibold"}
      >
        {brand.product_heading || "What is the item you would like us to send you? (Select one from below 👇)"}
      </h3>

      <Slider
        {...settings}
      >
        {products.map((p) => (
          <div
            className={`has-text-centered slider-item px-1`}
            onClick={() => setProduct(p.id)}
            key={p.id}
            style={{
              border: "2px blue solid",
              minHeight: "100%"
            }}
          >
            <div
              className={`is-clickable slider-item-child ${productId===p.id ? "is-selected-product" : ""}`}
            >
              {p.product_image_link && (
                <img
                  className={"slider-item-child-image"}
                  src={p.product_image_link}
                  alt={p.product_name}
                  title={p.product_name}
                  style={{
                    display: 'inline',
                    maxHeight: "220px"
                  }}
                />
              )}
              <h4
                className={"title slider-item-child-title p-2 is-capitalized has-text-weight-medium is-text-5 is-size-6-tablet is-size-7-mobile my-2"}
              >
                {p.product_name}
              </h4>
              <a
                href={p.product_link}
                target={"_blank"}
                className={"button px-3 is-text slider-item-child-button has-text-centered"}
                hidden={!p.product_link}
              >
                Checkout Product
                <i
                  className="fa fa-p1 px-2 fa-external-link has-text-black"
                  aria-hidden="true"
                />
              </a>
{/*              <button
                className="button is-text"
                hidden={!p.product_link}
                onClick={() => window.open(p.product_link, "_blank")}
              >
                Checkout Product
              </button>*/}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default ProductPage