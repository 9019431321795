import {AddressObj} from "./BrandPage";

type CollectAddressProps = {
  setAddress: Function,
  address: AddressObj
}

const CollectAddress = (
  {
    setAddress,
    address
  }: CollectAddressProps
) => {

  const updateAddress = (ad: AddressObj) => {
    setAddress(ad)
  }

  return (
    <div>
      <h4 className={"title has-text-left has-text-weight-semibold is-size-3-widescreen is-size-4-tablet is-size-5-mobile"}>
        Shipping Address
      </h4>
      <div
        className={"ib-form-area-comp"}
      >
        <i
          className="fa fa-ig-ib fa-2x fa-home"
          aria-hidden="true"
          hidden
        />
        <input
          id="ib-address-input"
          className="ib-input ib-address-input"
          placeholder={"Address Line 1"}
          value={address.line_1}
          onChange={e => updateAddress({...address, line_1: e.target.value})}
          name="line_1"
          required
        />
      </div>
      <div
        className={"ib-form-area-comp"}
      >
        <i
          className="fa fa-ig-ib fa-2x fa-home"
          aria-hidden="true"
          hidden
        />
        <input
          id="ib-address-input"
          className="ib-input ib-address-input"
          placeholder={"Address Line 2"}
          value={address.line_2}
          onChange={e => updateAddress({...address, line_2: e.target.value})}
          name="line_2"
          required
        />
      </div>
      <div className={"columns is-mobile is-variable is-3-desktop is-2-tablet is-1-mobile"}>
        <div className={"column pb-0 is-half"}>
          <div
            className={"ib-form-area-comp"}
          >
            <i
              className="fa fa-ig-ib fa-2x fa-home"
              aria-hidden="true"
              hidden
            />
            <input
              id="ib-address-input"
              className="ib-input ib-address-input"
              placeholder={"City"}
              value={address.city}
              onChange={e => updateAddress({...address, city: e.target.value})}
              title={"City"}
              name="city"
              required
            />
          </div>
        </div>
        <div className={"column pb-0 is-half"}>
          <div
            className={"ib-form-area-comp"}
          >
            <i
              className="fa fa-ig-ib fa-2x fa-home"
              aria-hidden="true"
              hidden
            />
            <input
              id="ib-address-input"
              className="ib-input ib-address-input"
              placeholder={"State"}
              title={"State"}
              value={address.state}
              onChange={e => updateAddress({...address, state: e.target.value})}
              name="state"
              required
            />
          </div>
        </div>
      </div>
      <div className={"columns is-mobile is-variable is-3-desktop is-2-tablet is-1-mobile"}>
        <div className={"column is-half pt-0"}>
          <div
            className={"ib-form-area-comp"}
          >
            <i
              className="fa fa-ig-ib fa-2x fa-home"
              aria-hidden="true"
              hidden
            />
            <input
              id="ib-address-input"
              className="ib-input ib-address-input"
              placeholder={"Country"}
              title={"Country"}
              value={address.country}
              onChange={e => updateAddress({...address, country: e.target.value})}
              name="country"
              required
            />
          </div>
        </div>
        <div className={"column pt-0 is-half"}>
          <div
            className={"ib-form-area-comp"}
          >
            <i
              className="fa fa-ig-ib fa-2x fa-home"
              aria-hidden="true"
              hidden
            />
            <input
              id="ib-address-input"
              className="ib-input ib-address-input"
              placeholder={"ZIP CODE"}
              title={"ZIP CODE"}
              value={address.pin}
              onChange={e => updateAddress({...address, pin: e.target.value})}
              name="pincode"
              required
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollectAddress