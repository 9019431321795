import Faq from "react-faq-component"
import './FAQ.css'
import {useEffect, useState} from "react";

const sullenFAQs = {
  "data": {
    "faq_items": [{
      "id": 132,
      "title": "I didn't get my exclusive 20% Discount code? ",
      "answer": "Make sure you tagged @sullenclothing in your story with the account's instagram username you gave us and your Instagram was \"PUBLIC\" when you posted the story. Also your instagram should have more than 25 followers.  If all this was true, do check your SPAM folder. Lastly try filling the \u003ca href=\"https://brand.influencerbit.com/sullen\" target=\"_blank\"\u003eambassador form\u003c/a\u003e again and mentioning us on IG again",
      "faq_group_id": 28,
      "is_live": true,
      "created_at": "2022-04-09T18:43:37.989Z",
      "updated_at": "2022-04-09T18:43:37.989Z",
      "priority": 1
    }, {
      "id": 131,
      "title": "Can I change my Instagram Username?",
      "answer": "Just signup on our Ambassador Program \u003cb\u003e\"again\"\u003c/b\u003e with a \u003cb\u003e\"different email\"\u003c/b\u003e and your revised Instagram Username.",
      "faq_group_id": 28,
      "is_live": true,
      "created_at": "2022-04-09T18:37:06.864Z",
      "updated_at": "2022-04-09T18:57:38.656Z",
      "priority": 0
    }, {
      "id": 133,
      "title": "My instagram username WASN'T made into a discount code?",
      "answer": "The discount code will not contain \"@\". Eg: Discount code will be \"SullenClothing\" and not \"@SullenClothing\".\u003cbr\u003eMake sure you tagged @sullenclothing in your story with the account's instagram username you gave us and \u003cb\u003eyour Instagram was \"PUBLIC\" when you posted the story\u003c/b\u003e. Also your instagram account should have more than 25 followers. \u003cbr\u003e Lastly try filling the form again and mention us on IG again. It should work.",
      "faq_group_id": 28,
      "is_live": true,
      "created_at": "2022-04-09T18:56:08.765Z",
      "updated_at": "2022-04-09T19:14:14.696Z",
      "priority": 0
    }, {
      "id": 134,
      "title": "Is there any commission you get if someone purchases from your Instagram username code?",
      "answer": "For now, there is NO commission. We send you an exclusive 20% discount code for you while your friends / family get 15% discount using your Instagram username code.",
      "faq_group_id": 28,
      "is_live": true,
      "created_at": "2022-04-11T05:36:09.974Z",
      "updated_at": "2022-04-11T05:36:09.974Z",
      "priority": 0
    }],
    "faq_groups": [{
      "id": 28,
      "name": "General",
      "faq_site_id": 16,
      "created_at": "2022-04-09T18:37:06.840Z",
      "updated_at": "2022-04-09T18:37:06.840Z",
      "priority": 0
    }]
  }, "success": true, "error": null
}

const petpawzFAQs = {
  "data": {
    "faq_items": [{
      "id": 131,
      "title": "Can I change my Instagram Username?",
      "answer": "Just signup on our Ambassador Program \u003cb\u003e\"again\"\u003c/b\u003e with a \u003cb\u003e\"different email\"\u003c/b\u003e and your revised Instagram Username.",
      "faq_group_id": 28,
      "is_live": true,
      "created_at": "2022-04-09T18:37:06.864Z",
      "updated_at": "2022-04-09T18:57:38.656Z",
      "priority": 0
    }, {
      "id": 133,
      "title": "My instagram username WASN'T made into a discount code?",
      "answer": "The discount code will not contain \"@\". Eg: Discount code will be \"theearthrhythm\" and not \"@theearthrhythm\".\u003cbr\u003eMake sure you tagged @theearthrhythm in your story with the account's instagram username you gave us and \u003cb\u003eyour Instagram was \"PUBLIC\" when you posted the story\u003c/b\u003e. Also your instagram account should have more than 25 followers. \u003cbr\u003e Lastly try filling the ambassador form again and mention us on IG again. It should work.",
      "faq_group_id": 28,
      "is_live": true,
      "created_at": "2022-04-09T18:56:08.765Z",
      "updated_at": "2022-04-09T19:14:14.696Z",
      "priority": 0
    }],
    "faq_groups": [{
      "id": 28,
      "name": "General",
      "faq_site_id": 16,
      "created_at": "2022-04-09T18:37:06.840Z",
      "updated_at": "2022-04-09T18:37:06.840Z",
      "priority": 0
    }]
  }, "success": true, "error": null
}

const erFAQs = {
  "data": {
    "faq_items": [{
      "id": 131,
      "title": "Can I change my Instagram Username?",
      "answer": "Just signup on our Ambassador Program \u003cb\u003e\"again\"\u003c/b\u003e with a \u003cb\u003e\"different email\"\u003c/b\u003e and your revised Instagram Username.",
      "faq_group_id": 28,
      "is_live": true,
      "created_at": "2022-04-09T18:37:06.864Z",
      "updated_at": "2022-04-09T18:57:38.656Z",
      "priority": 0
    }, {
      "id": 133,
      "title": "My instagram username WASN'T made into a discount code?",
      "answer": "The discount code will not contain \"@\". Eg: Discount code will be \"theearthrhythm\" and not \"@theearthrhythm\".\u003cbr\u003eMake sure you tagged @theearthrhythm in your story with the account's instagram username you gave us and \u003cb\u003eyour Instagram was \"PUBLIC\" when you posted the story\u003c/b\u003e. Also your instagram account should have more than 25 followers. \u003cbr\u003e Lastly try filling the ambassador form again and mention us on IG again. It should work.",
      "faq_group_id": 28,
      "is_live": true,
      "created_at": "2022-04-09T18:56:08.765Z",
      "updated_at": "2022-04-09T19:14:14.696Z",
      "priority": 0
    }],
    "faq_groups": [{
      "id": 28,
      "name": "General",
      "faq_site_id": 16,
      "created_at": "2022-04-09T18:37:06.840Z",
      "updated_at": "2022-04-09T18:37:06.840Z",
      "priority": 0
    }]
  }, "success": true, "error": null
}

const FAQs = (
  {
    campaign_id
  }: any
) => {
  const [items, setitems] = useState<any[]>([])

  useEffect(() => {
    let a: any[] = []
    let faq = petpawzFAQs
    if(campaign_id === 2) faq = sullenFAQs
    if(campaign_id === 5) faq = erFAQs
    faq.data.faq_items.map((b: any)=>(
      a.push({title: b.title, content: b.answer})
    ))
    // @ts-ignore
    setitems({rows: a})
  }, [])

  return(
    <div className="columns">
      <div className="column is-12">
        <FAQComponent
          data={items}
        />
      </div>
    </div>
  )
}

const FAQComponent = (
  {
    title = "FAQs",
    data = []
  }: any
) => {

  const styles = {
    bgColor: "transparent",
    titleTextColor: "white",
    rowTitleColor: "white",
    rowContentColor: "white",
    arrowColor: "white",
    padding: "10px",
  }

  return (
    <div
      className="container is-fluid faqContainer"
      style={{
        marginTop: 0
      }}
    >
      <div className="columns is-multiline is-mobile">
        <div className="column is-12 has-text-centered-tablet">
          <h3 className="is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-black faqHeader">
            {title}
          </h3>
          <p className="has-text-black is-size-4-desktop is-size-6-mobile is-size-4-tablet mt-3">
            Have a doubt? Check if we have answered it below!
          </p>
        </div>

        <div className="column is-12">
          <Faq data={data} styles={styles}  />
        </div>
      </div>
    </div>
  )
}

export default FAQs